import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ImageGalleryPager.module.scss';

const ImageGalleryPager = ({
    current,
    total,
    modifiers,
    untranslatedModifiers,
}) => {
    const pagerClasses = classNames(
        styles['ImageGalleryPager'],
        modifiers,
        untranslatedModifiers.map((x) => styles[x])
    );

    return (
        <div className={pagerClasses}>
            <div className={styles['ImageGalleryPager__CurrentPage']}>
                {current} / {total}
            </div>
        </div>
    );
};

ImageGalleryPager.propTypes = {
    current: PropTypes.node.isRequired,
    total: PropTypes.node.isRequired,
    modifiers: PropTypes.string.isRequired,
    untranslatedModifiers: PropTypes.arrayOf(PropTypes.string),
};

ImageGalleryPager.defaultProps = {
    current: 0,
    total: 0,
    modifiers: '',
    untranslatedModifiers: [],
};

export default ImageGalleryPager;
