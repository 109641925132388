import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import BackgroundImage from '../../components/BackgroundImage';
import Button from '../Button';
import { isExternalUrl } from '../../utils/url';

import styles from './CardGeneric.module.scss';
import { serializeImage } from '../../utils/SerializeImage';
import isEmpty from '../../utils/isEmpty';

const CardGeneric = ({
    buttonText,
    single,
    image,
    title,
    text,
    meta,
    href,
    label,
}) => {
    const { t } = useTranslation();

    const hasMetaData = !isEmpty(meta);

    const serializedImage = serializeImage(image, true);

    const classes = classNames(styles['CardGeneric'], {
        [styles['CardGeneric--Single']]: single,
        [styles['CardGeneric--Half']]: !single,
        [styles['CardGeneric--White']]: !hasMetaData,
        [styles['CardGeneric--Meta']]: hasMetaData,
    });

    let imageProps = {
        sizes: '(max-width: 750px) 100vw, (max-width: 1200px) 80vw, 1260px',
        quality: 75,
    };

    if (!single) {
        imageProps.sizes =
            '(max-width: 750px) 100vw, (max-width: 1200px) 50vw, 618px';
        imageProps.quality = 70;
    }

    const arrowType = isExternalUrl(href)
        ? `/svg/icon-diagonal-arrow.svg`
        : `/svg/icon-arrow.svg`;

    return (
        <article className={classes}>
            <div className={styles['CardGeneric__Image']}>
                {!isEmpty(image) && (
                    <BackgroundImage {...serializedImage} {...imageProps} />
                )}
                {!!label && (
                    <div className={styles['CardGeneric__Label']}>
                        <span
                            className={`CardGeneric__Label__Icon CardGeneric__Label__Icon--${label.icon}`}
                        ></span>
                        <h4 className={styles['CardGeneric__Label__Title']}>
                            {label.title}
                        </h4>
                    </div>
                )}
            </div>
            <div className={styles['CardGeneric__Content']}>
                <h2 className={styles['CardGeneric__Title']}>{title}</h2>
                <div className={styles['CardGeneric__Text']}>{text}</div>
                {!!href && (
                    <div className={styles['CardGeneric__Button']}>
                        <Button
                            link={href}
                            text={buttonText || t('cardGeneric.readMore')}
                            label={t('cardGeneric.readMoreLabel', { title })}
                            icon={arrowType}
                            type="Primary"
                            modifiers={[
                                single ? 'CardGenericSingle' : 'CardGeneric',
                            ]}
                        />
                    </div>
                )}
            </div>
            {hasMetaData && (
                <div className={styles['CardGeneric__Meta']}>
                    {Object.keys(meta).map((key, index) => (
                        <Meta key={index} category={key} items={meta[key]} />
                    ))}
                </div>
            )}
        </article>
    );
};

const Meta = ({ category, items }) => {
    const { t } = useTranslation();
    let metaValues;
    if (Array.isArray(items)) {
        metaValues = items
            .map((item) => {
                return item.label;
            })
            .join(', ');
    } else {
        metaValues = items;
    }

    const capitalizeLabel =
        category.charAt(0).toUpperCase() + category.slice(1);

    return (
        <div className={styles['CardGeneric__Meta__Item']}>
            {t(`cardGeneric.metaLabel${capitalizeLabel}`)}
            <span className={styles['CardGeneric__Meta__Text']}>
                {metaValues}
            </span>
        </div>
    );
};

Meta.propTypes = {};

Meta.defaultProps = {};

CardGeneric.propTypes = {
    href: PropTypes.string,
    image: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
    single: PropTypes.bool,
    meta: PropTypes.object,
    buttonText: PropTypes.string,
    page: PropTypes.object,
    label: PropTypes.object,
};

CardGeneric.defaultProps = {
    href: '',
    image: {},
    page: {},
    title: '',
    meta: {},
    text: '',
    label: null,
    single: false,
};

export default CardGeneric;
