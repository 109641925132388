import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { snakeToPascal } from '../../utils/caseconverters';
import styles from './StreamField.module.scss';
import Wysiwyg from '../Wysiwyg';
import ListCardEvent from '../ListCardEvent';
import ImageGallery from '../ImageGallery';
import ImageBlock from '../ImageBlock';
import VideoSegment from '../VideoSegment';
import Faq from '../Faq';
import ListCardContact from '../ListCardContact';
import ListCardToolkit from '../ListCardToolkit';
import ListCardArticle from '../ListCardArticle';
import Map from '../Map';
import PostSegment from '../PostSegment';
import CardInformation from '../CardInformation';
import ListDownloads from '../ListDownloads';
import ListCardGeneric from '../ListCardGeneric';
import NewsLetter from '../NewsLetter';
import CardRegion from '../CardRegion';
import CardTravelTimeSweden from '../CardTravelTimeSweden';
import CardTravelTimeEurope from '../CardTravelTimeEurope';
import CardTravelTimeWorld from '../CardTravelTimeWorld';
import ListCardRegion from '../ListCardRegion';

const Components = {
    WysiwygBlock: Wysiwyg,
    Wysiwyg,
    ListCardEvent,
    ImageGallery,
    Image: ImageBlock,
    VideoSegment,
    Faq,
    ListCardContact,
    ListCardToolkit,
    MapBlock: Map,
    PostSegment,
    CardInformation,
    ListDownloads,
    Map,
    ListCardGeneric,
    ListCardArticle,
    ArticleList: ListCardArticle,
    ArticleButtonList: ListCardGeneric,
    NewsLetter,
    NewsLetterBlock: NewsLetter,
    CardRegion,
    CardTravelTimeSweden,
    CardTravelTimeEurope,
    CardTravelTimeWorld,
    ListCardRegion,
};

const StreamField = ({ items }) => {
    if (!items.length) {
        return null;
    }
    let count = 0;

    const dynamicComponents = items.map((item) => {
        item.component = snakeToPascal(item.type);
        const Component = Components[item.component];

        if (!Component) {
            return null;
        }

        const props = {};

        if (item.component === 'Image') {
            props.modifiers = ['StreamFieldImage'];
        }

        count++;
        return (
            <div
                key={item.id}
                id={`sf-${item.id}`}
                className={classNames(
                    styles[`StreamField__Component`],
                    styles[`StreamField__Component--${item.component}`],
                    {
                        [styles['StreamField__Component--Last']]:
                            count === items.length,
                    }
                )}
            >
                <Component id={item.id} {...item.value} {...props} />
            </div>
        );
    });

    return <div className={styles['StreamField']}>{dynamicComponents}</div>;
};

StreamField.propTypes = {
    items: PropTypes.array,
};

StreamField.defaultProps = {
    items: [],
};

export default StreamField;
