import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardEvent from '../CardEvent';
import Button from '../Button';
import { useTranslation } from 'next-i18next';
import styles from './ListCardEvent.module.scss';

const sortEventsByDate = (items) => {
    return items.sort((a, b) => {
        const dateA = new Date(`${a.year}-${a.date.month}-${a.date.day}`);
        const dateB = new Date(`${b.year}-${b.date.month}-${b.date.day}`);
        return dateA - dateB;
    });
};

const groupByYear = (items) =>
    items.reduce((acc, value) => {
        if (!acc[value.year]) {
            acc[value.year] = [];
        }
        acc[value.year].push(value);
        return acc;
    }, {});

const ListCardEvent = ({ title, text, link, items, isPastEvents }) => {
    const { t } = useTranslation();
    const sortedItems = sortEventsByDate(items);

    const tabData = groupByYear(items);

    const maxYear = Math.max.apply(
        Math,
        items.map((item) => item.year)
    );
    const [activeYear, setActiveYear] = useState(maxYear);

    const [showAllEvents, setShowAllEvents] = useState(false);

    const visibleItems =
        !isPastEvents && !showAllEvents ? sortedItems.slice(0, 8) : sortedItems;

    const classes = classNames(styles['ListCardEvent'], {
        [styles['ListCardEvent--IsPastEvents']]: isPastEvents,
        [styles['ListCardEvent--IsOpen']]: showAllEvents,
    });

    return (
        <div className={classes}>
            {sortedItems && sortedItems.length > 0 && (
                <Fragment>
                    <div className={styles['ListCardEvent__Header']}>
                        {title && (
                            <h2 className={styles['ListCardEvent__Title']}>
                                {title}
                            </h2>
                        )}
                        {isPastEvents && Object.keys(tabData).length > 1 && (
                            <Tabs
                                items={tabData}
                                setActiveYear={setActiveYear}
                                activeYear={activeYear}
                            />
                        )}
                    </div>
                    {text && (
                        <div className={styles['ListCardEvent__Preamble']}>
                            {text}
                        </div>
                    )}
                    <div className={styles['ListCardEvent__Content']}>
                        {!isPastEvents
                            ? visibleItems.map((item, index) => (
                                  <div
                                      key={index}
                                      className={
                                          styles['ListCardEvent__Event']
                                      }>
                                      <CardEvent
                                          {...item}
                                          modifiers={[
                                              isPastEvents ? 'Past' : null,
                                          ]}
                                      />
                                  </div>
                              ))
                            : tabData[activeYear].map((item, index) => (
                                  <div
                                      key={index}
                                      className={
                                          styles['ListCardEvent__Event']
                                      }>
                                      <CardEvent
                                          {...item}
                                          modifiers={[
                                              isPastEvents ? 'Past' : null,
                                          ]}
                                      />
                                  </div>
                              ))}
                    </div>
                    {!isPastEvents && sortedItems.length > 4 && (
                        <span
                            className={styles['ListCardEvent__Expand']}
                            onClick={() => setShowAllEvents(!showAllEvents)}>
                            {showAllEvents
                                ? t('listCardEvent.shrink')
                                : t('listCardEvent.expand')}
                        </span>
                    )}
                    {!!link && link.href && (
                        <div className={styles['ListCardEvent__More']}>
                            <Button
                                type={'Secondary'}
                                text={link.title}
                                link={link.href}
                                icon={'/svg/icon-arrow.svg'}
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
};

const Tabs = ({ items, setActiveYear = () => null, activeYear }) => (
    <ul className={styles['ListCardEvent__Tabs']}>
        {Object.keys(items)
            .reverse()
            .map((year, i) => (
                <li key={i}>
                    <button
                        onClick={() => setActiveYear(year)}
                        className={classNames(
                            styles['ListCardEvent__TabButton'],
                            {
                                [styles['ListCardEvent__TabButton--Active']]:
                                    String(activeYear) === String(year),
                            }
                        )}>
                        {year}
                    </button>
                </li>
            ))}
    </ul>
);

ListCardEvent.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    isPastEvents: PropTypes.bool,
};

ListCardEvent.defaultProps = {
    items: [],
    title: '',
    text: '',
    href: '',
    isPastEvents: false,
};

export default ListCardEvent;
