import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
// import Icon from '../../Icon';

import styles from './ImageGalleryFullscreenButton.module.scss';

const ImageGalleryFullscreenButton = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['ImageGalleryFullscreenButton']}>
            <button
                className={styles['ImageGalleryFullscreenButton__Button']}
                title={t('imagegallery.showfullscreen')}
                aria-label={t('imagegallery.showfullscreen')}
                onClick={onClick}
            >
                <span className="sr-only">
                    {t('imagegallery.showfullscreen')}
                </span>
            </button>
        </div>
    );
};

ImageGalleryFullscreenButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ImageGalleryFullscreenButton;
