import React from 'react';
import PropTypes from 'prop-types';
import styles from './ListDownloads.module.scss';

const ListDownloads = ({ title, items }) => (
    <div className={styles['ListDownloads']}>
        {title && <h2 className={styles['ListDownloads__Title']}>{title}</h2>}
        {items.map((item, index) => (
            <a
                key={index}
                className={styles['ListDownloads__Item']}
                href={item.link.href}
                download
            >
                {item.title || item.link.title}
            </a>
        ))}
    </div>
);

ListDownloads.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
};

ListDownloads.defaultProps = {
    title: '',
    items: [],
};

export default ListDownloads;
