import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

import Wysiwyg from '../Wysiwyg';

import styles from './Faq.module.scss';

const Faq = ({ id, heading, items }) => (
    <div className={styles['Faq']}>
        <div className={styles['Faq__Content']}>
            {heading && <h4 className={styles['Faq__Heading']}>{heading}</h4>}

            {items.map((item, i) => {
                const qaItem = {
                    id: item.id ? item.id : `faq-${id}-item-${i}`,
                    ...item.value,
                };

                return <Item key={qaItem.id} item={qaItem} />;
            })}
        </div>
    </div>
);

const Item = ({ item }) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);
    const itemContainerRef = useRef(null);

    const toggleExpanded = () => setExpanded(!expanded);

    // https://www.w3.org/WAI/GL/wiki/Using_the_WAI-ARIA_aria-expanded_state_to_mark_expandable_and_collapsible_regions#Example_1:_Using_a_button_to_collapse_and_expand_a_region
    useEffect(() => {
        if (expanded) {
            itemContainerRef.current.focus();
        }
    }, [expanded]);

    /* eslint-disable indent */
    const itemClasses = classNames(styles['Faq__Item'], {
        [styles['Faq__Item--Expanded']]: expanded,
    });

    return (
        <div className={itemClasses}>
            <button
                className={styles['Faq__Item__Title']}
                onClick={toggleExpanded}
                aria-controls={item.id}
                aria-expanded={expanded}
                title={expanded ? t('faq.close') : t('faq.open')}
            >
                {item.question}
            </button>
            <AnimateHeight duration={350} height={expanded ? 'auto' : 0}>
                <div
                    id={item.id}
                    className={styles['Faq__Item__Content']}
                    role="region"
                    tabIndex="-1"
                    ref={itemContainerRef}
                >
                    <Wysiwyg html={item.answer} modifier={['Inner']} />
                </div>
            </AnimateHeight>
        </div>
    );
};

Faq.propTypes = {
    id: PropTypes.string.isRequired,
    heading: PropTypes.string,
    items: PropTypes.array,
};

Faq.defaultProps = {
    heading: null,
    items: [],
    id: 'aaaa-bbbb-cccc-dddd',
};

Item.propTypes = {
    id: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
};

Item.defaultProps = {
    id: '',
    items: [],
};

export default Faq;
