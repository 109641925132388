import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardTravelTimeSweden.module.scss';
import TransportChooser from '../TransportChooser';
import TravelTimeHeader from '../TravelTimeHeader';

const CardTravelTimeSweden = ({ title, preamble, chooserTitle }) => {
    const [activeTransport, setActiveTransport] = useState('train');
    const availiableTransports = [
        { value: 'train' },
        { value: 'plane' },
        { value: 'car' },
        { value: 'bike' },
        { value: 'boat' },
    ];

    return (
        <div className={styles['CardTravelTimeSweden']}>
            <div className={styles['CardTravelTimeSweden__Container']}>
                <div className={styles['CardTravelTimeSweden__Content']}>
                    <div className={styles['CardTravelTimeSweden__Header']}>
                        <TravelTimeHeader title={title} preamble={preamble} />
                    </div>
                    <h4 className={styles['CardTravelTimeSweden__SubTitle']}>
                        {chooserTitle}
                    </h4>
                    <TransportChooser
                        transports={availiableTransports}
                        activeTransport={activeTransport}
                        onChange={setActiveTransport}
                        name="transport-sweden"
                    />
                </div>
                <div className={styles['CardTravelTimeSweden__MapContainer']}>
                    {availiableTransports.map(({ value }, i) => (
                        <img
                            key={i}
                            role="tabpanel"
                            id={value}
                            aria-hidden={activeTransport !== value}
                            className={classNames(
                                styles['CardTravelTimeSweden__Map'],
                                {
                                    [styles[
                                        'CardTravelTimeSweden__Map--Active'
                                    ]]: activeTransport === value,
                                }
                            )}
                            src={`/img/${value}.png`}
                            alt={`Map of sweden`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

CardTravelTimeSweden.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    chooserTitle: PropTypes.string,
};

CardTravelTimeSweden.defaultProps = {
    title: '',
    preamble: '',
    chooserTitle: '',
};

export default CardTravelTimeSweden;
