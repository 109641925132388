import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CardRegion.module.scss';
import Wysiwyg from '../Wysiwyg';

const CardRegion = ({
    title,
    preamble,
    image,
    content,
    region,
    buttonText,
    regionButtonsData,
    setRegion,
    visible,
}) => {
    return (
        <div className={styles['CardRegion']}>
            <div className={styles['CardRegion__ImageContainer']}>
                {image?.src && (
                    <div
                        className={styles['CardRegion__BgImage']}
                        style={{
                            backgroundImage: `url(${image.src})`,
                        }}
                    />
                )}
                <img
                    className={styles['CardRegion__Map']}
                    src={`/img/map-${region}.svg`}
                    alt={`Map of ${region} sweden`}
                />
                <div className={styles['CardRegion__ChangeButtonContainer']}>
                    {regionButtonsData.map((buttonData, index) => (
                        <button
                            key={index}
                            tabIndex={visible ? 0 : -1}
                            onClick={() => setRegion(index)}
                            className={classNames(
                                styles['CardRegion__ChangeButton'],
                                {
                                    [styles[
                                        'CardRegion__ChangeButton--Active'
                                    ]]: buttonData.text === buttonText,
                                }
                            )}
                        >
                            {buttonData.text}
                        </button>
                    ))}
                </div>
            </div>
            <div className={styles['CardRegion__TextContainer']}>
                <h2 className={styles['CardRegion__Title']}>{title}</h2>
                <p className={styles['CardRegion__Preamble']}>{preamble}</p>
                {!!content && (
                    <div className={styles['CardRegion__Content']}>
                        <Wysiwyg html={content} modifiers={['CardRegion']} />
                    </div>
                )}
            </div>
        </div>
    );
};

CardRegion.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.string,
    region: PropTypes.oneOf(['north', 'middle', 'south']),
    regionButtonsData: PropTypes.array,
    setRegion: PropTypes.func,
    visible: PropTypes.bool,
};

CardRegion.defaultProps = {
    title: '',
    preamble: '',
    image: {},
    content: '',
    region: 'north',
    regionButtonsData: [],
    setRegion: () => null,
    visible: true,
};

export default CardRegion;
