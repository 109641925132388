import React from 'react';
import styles from './CardEvent.module.scss';
import classNames from 'classnames';

const CardEvent = ({ link, date, text, place, modifiers = [] }) => {
    if (!link) {
        return null;
    }
    const classes = classNames(
        styles['CardEvent'],
        modifiers.map((modifier) => styles[`CardEvent--${modifier}`])
    );

    return (
        <a href={link.href} className={classes}>
            <div className={styles['CardEvent__ShortDate']}>
                <span className={styles['CardEvent__Day']}>{date.day}</span>
                <span className={styles['CardEvent__Month']}>{date.month}</span>
            </div>
            <div className={styles['CardEvent__Content']}>
                <h2 className={styles['CardEvent__Title']}>{link.title}</h2>
                <span className={styles['CardEvent__Meta']}>{text}</span>
            </div>
        </a>
    );
};

export default CardEvent;
