import React from 'react';
import PropTypes from 'prop-types';
import styles from './TransportChooser.module.scss';
import classNames from 'classnames';

const TransportChooser = ({
    transports,
    activeTransport,
    onChange,
    name,
    modifiers = [],
}) => {
    const classes = classNames(
        styles['TransportChooser'],
        modifiers.map((modifier) => styles[`TransportChooser--${modifier}`])
    );

    return (
        <div className={classes}>
            {transports.map(({ value }, i) => (
                <button
                    className={styles['TransportChooser__Chooser']}
                    key={i}
                    role="tab"
                    aria-selected={value === activeTransport}
                    aria-controls={value}
                    id={name}
                    onClick={() => onChange(value)}
                >
                    <span
                        className={classNames(
                            styles['TransportChooser__ChooserMark'],
                            {
                                [styles[
                                    `TransportChooser__ChooserMark--${value}`
                                ]]: value,
                            }
                        )}
                    ></span>
                    <span className="sr-only">Train</span>
                </button>
            ))}
        </div>
    );
};

TransportChooser.propTypes = {
    transports: PropTypes.array,
    activeTransport: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    modifiers: PropTypes.array,
};

TransportChooser.defaultProps = {
    transports: [],
    activeTransport: '',
    onChange: () => null,
    name: 'transport',
    modifiers: [],
};

export default TransportChooser;
