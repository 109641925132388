import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import styles from './CardTravelTimeWorld.module.scss';
import { useTranslation } from 'next-i18next';
import TravelTimeHeader from '../TravelTimeHeader';

const CardTravelTimeWorld = ({ title, preamble }) => {
    const containerRef = useRef();
    const imgRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (
            !!containerRef &&
            !!containerRef.current &&
            !!containerRef.current.scroll &&
            imgRef.current &&
            typeof document !== 'undefined'
        ) {
            let docWidth = document.documentElement.clientWidth;
            if (docWidth === 0) {
                docWidth = 365;
            }
            const width = imgRef.current?.clientWidth / 2 - docWidth / 2;
            containerRef.current?.scroll(width, 0);
        }
    }, [containerRef, imgRef]);

    return (
        <div className={styles['CardTravelTimeWorld']}>
            <div className={styles['CardTravelTimeWorld__Container']}>
                <div className={styles['CardTravelTimeWorld__Header']}>
                    <TravelTimeHeader title={title} preamble={preamble} />
                </div>
            </div>
            <div className={styles['CardTravelTimeWorld__MapContainer']}>
                <div
                    ref={containerRef}
                    className={styles['CardTravelTimeWorld__ImageContainer']}
                >
                    <img
                        ref={imgRef}
                        className={styles['CardTravelTimeWorld__Map']}
                        src={`/img/map-flighttimes.png`}
                        alt={`Map of the World`}
                    />
                </div>
                <div className={styles['CardTravelTimeWorld__MapMobileInfo']}>
                    {t('worldMap.mobileInstruction')}
                </div>
            </div>
        </div>
    );
};

CardTravelTimeWorld.propTypes = {};

CardTravelTimeWorld.defaultProps = {};

export default CardTravelTimeWorld;
