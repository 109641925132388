import { createContext, useReducer } from 'react';

const LocalStateContext = createContext(null);

const useLocalStateContext = (images) => {
    const [state, dispatch] = useReducer(imageGalleryReducer, {
        selectedImage: images[0],
        selectedImageIndex: 1,

        displayThumbIndex: '',
        displayMultiThumbIndex: '',
        visibleThumbIdxs: [],
        lastVisibleThumbIdx: 1,

        isFullscreen: false,
    });

    const handleImageInViewChange = (visible, image) => {
        if (visible) {
            dispatch({
                type: 'ADD_VISIBLE_THUMB_INDEX',
                payload: { index: image.index },
            });
        } else {
            dispatch({
                type: 'REMOVE_VISIBLE_THUMB_INDEX',
                payload: { index: image.index },
            });
        }
    };

    const handleImageSelect = (e, image) => {
        e.stopPropagation();
        dispatch({
            type: 'SET_SELECTED_IMAGE',
            payload: {
                item: image,
            },
        });
    };

    const handleFullscreenToggle = () => {
        dispatch({ type: 'TOGGLE_FULLSCREEN' });
    };

    const context = {
        state,
        handleImageInViewChange,
        handleImageSelect,
        handleFullscreenToggle,
    };

    return context;
};

const imageGalleryReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SELECTED_IMAGE': {
            return {
                ...state,
                selectedImageIndex: action.payload.item.index,
                selectedImage: action.payload.item,
            };
        }

        case 'TOGGLE_FULLSCREEN': {
            return {
                ...state,
                isFullscreen: !state.isFullscreen,
            };
        }

        case 'ADD_VISIBLE_THUMB_INDEX': {
            const visibleThumbIdxs = [
                ...state.visibleThumbIdxs,
                action.payload.index,
            ].sort();

            const displayThumbIndex = getVisibleThumbsIndex(
                visibleThumbIdxs,
                state.lastVisibleThumbIdx
            );
            const displayMultiThumbIndex = getVisibleThumbsIndex(
                visibleThumbIdxs,
                state.lastVisibleThumbIdx,
                false
            );

            return {
                ...state,
                visibleThumbIdxs,
                displayThumbIndex,
                displayMultiThumbIndex,
            };
        }

        case 'REMOVE_VISIBLE_THUMB_INDEX': {
            const lastVisibleThumbIdx = action.payload.index;
            const visibleThumbIdxs = state.visibleThumbIdxs
                .filter((i) => i !== action.payload.index)
                .sort();

            const displayThumbIndex = getVisibleThumbsIndex(
                visibleThumbIdxs,
                lastVisibleThumbIdx
            );
            const displayMultiThumbIndex = getVisibleThumbsIndex(
                visibleThumbIdxs,
                lastVisibleThumbIdx,
                false
            );

            return {
                ...state,
                visibleThumbIdxs,
                lastVisibleThumbIdx,
                displayThumbIndex,
                displayMultiThumbIndex,
            };
        }

        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

const getVisibleThumbsIndex = (
    visibleThumbIdxs,
    lastVisibleThumbIdx,
    singleIndex = true
) => {
    // edge case where we seem to have no index (happens on certain breaks when scrolling)
    if (visibleThumbIdxs.length === 0) {
        return lastVisibleThumbIdx;
    }

    // only one visible thumb
    if (visibleThumbIdxs.length === 1) {
        return visibleThumbIdxs[0];
    }

    // multiple visible thumbs, determine one index based on scroll direction
    if (visibleThumbIdxs.length > 1 && singleIndex) {
        const first = visibleThumbIdxs[visibleThumbIdxs.length - 1];
        const second = visibleThumbIdxs[0];
        const last = lastVisibleThumbIdx;

        if (first - last > 0) {
            // scrolling right
            return first;
        } else {
            // scrolling left
            return second;
        }
    }

    // multiple visible thumbs, return an index range
    if (visibleThumbIdxs.length > 1 && !singleIndex) {
        let [first, second] = [visibleThumbIdxs[0], visibleThumbIdxs.slice(-1)];

        return first === second ? first : [first, second].join('-');
    }

    return 0;
};

export { useLocalStateContext, LocalStateContext };
