import React from 'react';
import BackgroundImage from '../../components/BackgroundImage';

import { serializeImage } from '../../utils/SerializeImage';

import styles from './CardContact.module.scss';

const CardContact = ({ name, email, image }) => {
    if (!name) {
        return null;
    }
    const serializedImage = serializeImage(image, true);
    const sizes = '256px';

    return (
        <div className={styles['CardContact']}>
            <div className={styles['CardContact__Image']}>
                <BackgroundImage
                    {...serializedImage}
                    quality={60}
                    sizes={sizes}
                    modifiers={['CardContact']}
                />
            </div>

            <div className={styles['CardContact__Wrapper']}>
                <div className={styles['CardContact__Name']}>{name}</div>
                <div className={styles['CardContact__Email']}>
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
        </div>
    );
};

export default CardContact;
