import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ImageGalleryFullscreenCaption.module.scss';

const ImageGalleryFullscreenCaption = ({
    image,
    isActive,
    totalImageCount,
}) => {
    const caption = image.alt;
    const current = image.index;

    const infoClasses = classNames(styles['ImageGalleryFullscreenCaption'], {
        [styles['ImageGalleryFullscreenCaption--Hidden']]: !isActive,
    });

    return (
        <div className={infoClasses}>
            <span className={styles['ImageGalleryFullscreenCaption__Caption']}>
                {caption}
            </span>
            <span className={styles['ImageGalleryFullscreenCaption__Pager']}>
                {current} / {totalImageCount}
            </span>
        </div>
    );
};

ImageGalleryFullscreenCaption.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        index: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        alt: PropTypes.string,
        portrait: PropTypes.bool,
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    totalImageCount: PropTypes.number.isRequired,
};

ImageGalleryFullscreenCaption.defaultProps = {
    isActive: true,
};

export default ImageGalleryFullscreenCaption;
