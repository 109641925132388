export function serializeImage(obj, shouldNotHaveAlt) {
    if (!obj) {
        return null;
    }

    const alt = obj.altText || obj.title || undefined;
    const credits = obj.credits || undefined;
    const width = obj.width || undefined;
    const height = obj.height || undefined;
    const focal = obj.focal || undefined;

    return {
        src: obj.url || obj.src,
        alt: shouldNotHaveAlt ? '' : alt,
        credits: credits,
        width: width,
        height: height,
        focal: focal,
    };
}
