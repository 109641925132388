import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardTravelTimeEurope.module.scss';
import TravelTimeHeader from '../TravelTimeHeader';
import TransportChooser from '../TransportChooser';
import Wysiwyg from '../Wysiwyg';

const CardTravelTimeEurope = ({
    title,
    preamble,
    chooserTitle,
    travelTimeTexts,
}) => {
    const [activeTransport, setActiveTransport] = useState('train');

    return (
        <div className={styles['CardTravelTimeEurope']}>
            <div className={styles['CardTravelTimeEurope__Container']}>
                <div className={styles['CardTravelTimeEurope__Header']}>
                    <TravelTimeHeader title={title} preamble={preamble} />
                </div>
            </div>

            <div className={styles['CardTravelTimeEurope__MapContainer']}>
                <div
                    className={styles['CardTravelTimeEurope__TransportChooser']}
                >
                    <div
                        className={
                            styles['CardTravelTimeEurope__ChooserContent']
                        }
                    >
                        <h5
                            className={
                                styles['CardTravelTimeEurope__ChooserTitle']
                            }
                        >
                            {chooserTitle}
                        </h5>
                        <TransportChooser
                            transports={[
                                { value: 'train' },
                                { value: 'plane' },
                                { value: 'car' },
                            ]}
                            activeTransport={activeTransport}
                            onChange={setActiveTransport}
                            name="transport-europe"
                            modifiers={['ChooserVertical']}
                        />
                    </div>
                </div>

                <img
                    className={styles['CardTravelTimeEurope__Map']}
                    src={`/img/map-transporttimes.png`}
                    alt={`Map of Europe`}
                />
                {travelTimeTexts.map(({ type, content }, i) => (
                    <div
                        aria-hidden={activeTransport !== type}
                        key={i}
                        role="tabpanel"
                        id={type}
                        className={classNames(
                            styles['CardTravelTimeEurope__TravelTimeText'],
                            {
                                [styles[
                                    'CardTravelTimeEurope__TravelTimeText--Active'
                                ]]: activeTransport === type,
                            }
                        )}
                    >
                        <div
                            className={
                                styles[
                                    'CardTravelTimeEurope__TravelTimeContent'
                                ]
                            }
                        >
                            <Wysiwyg
                                html={content}
                                modifiers={['CardTravelTimeEurope']}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

CardTravelTimeEurope.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    chooserTitle: PropTypes.string,
    travelTimeTexts: PropTypes.array,
};

CardTravelTimeEurope.defaultProps = {
    title: '',
    preamble: '',
    chooserTitle: '',
    travelTimeTexts: [],
};

export default CardTravelTimeEurope;
