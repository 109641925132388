import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import FadeIn from '../../transitions/FadeIn';

import debounce from '../../utils/debounce';
import { breakpoints } from '../../constants';

import classNames from 'classnames';
import isEmpty from '../../utils/isEmpty';
import useMediaQuery from '../../utils/useMediaQuery';

import { useTranslation } from 'next-i18next';

import CardArticle from '../../components/CardArticle';

import styles from './ListCardArticle.module.scss';

const useScrollPosition = (elem) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const listener = debounce(() => {
        setScrollPosition(elem.current.scrollLeft);
    }, 200);

    useEffect(() => {
        if (!elem.current) {
            return;
        }

        const el = elem.current;

        el.addEventListener('scroll', listener, { passive: true });
        return () => {
            el.removeEventListener('scroll', listener);
        };
    }, [elem, listener]);

    return {
        scrollPosition,
    };
};

const ListCardArticle = ({ items, href, title, preamble, usp, showBorder }) => {
    const { t } = useTranslation();

    const sliderElement = useRef(null);
    const { scrollPosition } = useScrollPosition(sliderElement);

    const keys = [32, 13];

    const slide = (dir, e) => {
        e.preventDefault();

        if (e.keyCode && keys.indexOf(e.keyCode) === -1) {
            return;
        }

        if (!sliderElement.current) {
            return;
        }

        const scrollLength = sliderElement.current.clientWidth / 2;

        let scroll = {
            behavior: 'smooth',
        };

        if (dir === 'left') {
            scroll.left = scrollPosition - scrollLength;
        } else {
            scroll.left = scrollPosition + scrollLength;
        }

        sliderElement.current.scroll(scroll);
    };

    if (isEmpty(items)) {
        return null;
    }

    const slideRight = (e) => slide('right', e);
    const slideLeft = (e) => slide('left', e);

    const percentageScrolled = sliderElement.current
        ? (scrollPosition /
              (sliderElement.current.scrollWidth -
                  sliderElement.current.clientWidth)) *
          100
        : 0;

    const classes = classNames(styles['ListCardArticle'], {
        [styles['ListCardArticle--HasIndicator']]: items.length > 4,
        [styles['ListCardArticle--NoHeader']]: !title,
    });

    let cardModifier = '';
    if (items.length >= 4) {
        cardModifier = 'Four';
    } else if (items.length === 3) {
        cardModifier = 'Tripple';
    } else if (items.length === 2) {
        cardModifier = 'Double';
    } else if (items.length === 1) {
        cardModifier = 'Single';
    }

    return (
        <div className={classes}>
            <div className={styles['ListCardArticle__Container']}>
                {title && (
                    <div
                        className={classNames(
                            styles['ListCardArticle__Header'],
                            {
                                [styles['ListCardArticle__Header--HasBorder']]:
                                    usp || preamble || showBorder,
                            }
                        )}
                    >
                        <h2 className={styles['ListCardArticle__Title']}>
                            {title}
                        </h2>

                        {href && (
                            <a
                                className={styles['ListCardArticle__Link']}
                                href={href}
                            >
                                {t('listCardArticle.viewAll')}
                            </a>
                        )}

                        {(preamble || usp) && (
                            <div
                                className={styles['ListCardArticle__Preamble']}
                            >
                                {usp || preamble}
                            </div>
                        )}
                    </div>
                )}

                <div className={styles['ListCardArticle__SlideWrap']}>
                    <span className="sr-only">
                        {t('listCardArticle.scrollDescription')}
                    </span>
                    <ul
                        className={styles['ListCardArticle__Slidable']}
                        ref={sliderElement}
                    >
                        {items.map((item, index) => (
                            <CardArticle
                                {...item}
                                index={index}
                                key={'Card-' + index}
                                modifier={cardModifier}
                                containerModifier="ListCardArticle"
                            />
                        ))}
                    </ul>
                </div>

                <FadeIn visible={items.length > 4}>
                    <div className={styles['ListCardArticle__Arrows']}>
                        <button
                            className={styles['ListCardArticle__ArrowLeft']}
                            type="button"
                            onClick={(e) => slideLeft(e)}
                        >
                            <span className="sr-only">
                                {t('listCardArticle.scrollLeft')}
                            </span>
                        </button>

                        <button
                            className={styles['ListCardArticle__ArrowRight']}
                            type="button"
                            onClick={(e) => slideRight(e)}
                        >
                            <span className="sr-only">
                                {t('listCardArticle.scrollRight')}
                            </span>
                        </button>
                    </div>
                </FadeIn>

                {items.length > 3 && (
                    <ArticleListPager
                        itemsCount={items.length}
                        percentageScrolled={percentageScrolled}
                    />
                )}
            </div>
        </div>
    );
};

const ArticleListPager = ({ itemsCount, percentageScrolled }) => {
    const isResponsive = useMediaQuery({
        query: `(max-width: ${breakpoints.m}px)`,
    });

    if (!isResponsive && itemsCount <= 4) {
        return null;
    }

    return (
        <div className={styles['ListCardArticle__Pager']}>
            <div className={styles['ListCardArticle__PagerBg']}>
                <div
                    className={styles['ListCardArticle__PagerContent']}
                    style={{ width: `${percentageScrolled}%` }}
                ></div>
            </div>
        </div>
    );
};

ListCardArticle.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    items: PropTypes.array,
    title: PropTypes.string,
    preamble: PropTypes.string,
    usp: PropTypes.string,
    href: PropTypes.string,
    showBorder: PropTypes.bool,
};

ListCardArticle.defaultProps = {
    items: [],
    title: '',
    preamble: '',
    usp: '',
    href: '',
    showBorder: false,
};

ArticleListPager.propTypes = {
    itemsCount: PropTypes.number,
    percentageScrolled: PropTypes.number,
};

ArticleListPager.defaultProps = {
    itemsCount: 0,
    percentageScrolled: 0,
};

export default ListCardArticle;
