import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from 'next-i18next';

import CardGeneric from '../CardGeneric';

import styles from './ListCardGeneric.module.scss';

const ListCardGeneric = ({ title, text, link, items }) => {
    const { t } = useTranslation();

    const isSingle = items.length === 1;

    const classes = classNames(styles['ListCardGeneric'], {
        [styles['ListCardGeneric--Single']]: isSingle,
    });

    return (
        <div className={classes}>
            <div className={styles['ListCardGeneric__Wrapper']}>
                {title && (
                    <div className={styles['ListCardGeneric__Header']}>
                        <div
                            className={
                                styles['ListCardGeneric__Text-Container']
                            }
                        >
                            {title && (
                                <h2
                                    className={styles['ListCardGeneric__Title']}
                                >
                                    {title}
                                </h2>
                            )}
                            {text && (
                                <p className={styles['ListCardGeneric__Text']}>
                                    {text}
                                </p>
                            )}
                        </div>
                        {!!link && link.href && (
                            <a
                                href={link.href}
                                className={styles['ListCardGeneric__Link']}
                            >
                                {link.title || t('listCardGeneric.viewAll')}
                            </a>
                        )}
                    </div>
                )}
                <div className={styles['ListCardGeneric__Items']}>
                    {items.length > 0 &&
                        items.map((item, index) => (
                            <CardGeneric
                                {...item}
                                key={index}
                                single={isSingle}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

ListCardGeneric.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    items: PropTypes.array,
};

ListCardGeneric.defaultProps = {
    title: '',
    text: '',
    href: '',
    items: [],
};

export default ListCardGeneric;
