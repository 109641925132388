import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import styles from './CardInformation.module.scss';

import Button from '../Button';
import Wysiwyg from '../Wysiwyg';

const CardInformation = ({
    title,
    content,
    link,
    date,
    location,
    children,
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles['CardInformation']}>
            <h2 className={styles['CardInformation__Title']}>{title}</h2>

            {!!date && (
                <p className={styles['CardInformation__Text']}>
                    <strong className={styles['CardInformation__Text--Bold']}>
                        {t('cardInformation.when')}:{' '}
                    </strong>
                    {date}
                </p>
            )}

            {!!location && (
                <p className={styles['CardInformation__Text']}>
                    <strong className={styles['CardInformation__Text--Bold']}>
                        {t('cardInformation.where')}:{' '}
                    </strong>
                    {location}
                </p>
            )}

            <Wysiwyg html={content} modifiers={['NoMarginBottom']} />
            {link && link.href && (
                <div className={styles['CardInformation__Button']}>
                    <Button
                        type={'Primary'}
                        text={link.title}
                        link={link.href}
                    />
                </div>
            )}
            {!!children && children}
        </div>
    );
};

CardInformation.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.object,
};

CardInformation.defaultProps = {
    title: '',
    content: '',
    link: {},
};

export default CardInformation;
