import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { isExternalUrl } from '../../utils/url';
import BackgroundImage from '../BackgroundImage';
import VideoSegment from '../VideoSegment';
import { serializeImage } from '../../utils/SerializeImage';

import styles from './PostSegment.module.scss';

const PostSegment = ({ items }) => (
    <div className={styles['PostSegment']}>
        {items.map((item, index) => (
            <Card {...item} key={index} index={index} />
        ))}
    </div>
);

const Card = ({ href, image, video, title, text }) => {
    const { t } = useTranslation();

    const hasLink = href.length > 0;
    const hasVideo = video && video.videoUrl ? true : false;

    const serializedImage = serializeImage(image, true);

    const [isExternal, setIsExternal] = useState(false);
    const classes = classNames(styles['PostSegment__Card'], {
        [styles['PostSegment__Card--Link']]: hasLink,
        [styles['PostSegment__Card--External']]: isExternal,
    });

    const sizes = '(max-width: 750px) 100vw, (max-width: 1200px) 50vw, 640px';

    useEffect(() => {
        setIsExternal(isExternalUrl(href));
    }, [href]);

    return (
        <div className={classes}>
            {hasVideo && (
                <div className={styles['PostSegment__Video']}>
                    <VideoSegment {...video} modifier="PostSegment" />
                </div>
            )}

            {image && image.url && !hasVideo && (
                <div className={styles['PostSegment__Image']}>
                    <BackgroundImage
                        {...serializedImage}
                        sizes={sizes}
                        quality={70}
                    />
                </div>
            )}

            <div className={styles['PostSegment__Content']}>
                <h3 className={styles['PostSegment__Title']}>{title}</h3>
                <div className={styles['PostSegment__Text']}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    {hasLink && (
                        <a
                            href={href}
                            className={styles['PostSegment__ReadMore']}
                            target={isExternal ? '_blank' : null}
                        >
                            <span className="sr-only">{title}</span>
                            <span aria-hidden={true}>
                                {t('postsegment.more')}
                            </span>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

PostSegment.propTypes = {
    items: PropTypes.array,
};

PostSegment.defaultProps = {
    items: [],
};

Card.propTypes = {
    href: PropTypes.string,
    image: PropTypes.object,
    video: PropTypes.object,
    title: PropTypes.string,
    text: PropTypes.string,
};

Card.defaultProps = {
    href: '',
    image: {},
    video: {},
    title: '',
    text: '',
};

export default PostSegment;
