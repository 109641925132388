import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ListCardRegion.module.scss';
import CardRegion from '../CardRegion';

const ListCardRegion = ({ items }) => {
    const regionButtonsData = items.map((item, index) => ({
        text: item.buttonText,
        index,
    }));
    const [currentRegion, setCurrentRegion] = useState(0);
    return (
        <div className={styles['ListCardRegion']}>
            {items.map((item, index) => (
                <div
                    key={index}
                    className={classNames(styles['ListCardRegion__Card'], {
                        [styles['ListCardRegion__Card--Current']]:
                            index === currentRegion,
                    })}
                >
                    <CardRegion
                        key={index}
                        {...item}
                        visible={index === currentRegion}
                        regionButtonsData={regionButtonsData}
                        setRegion={setCurrentRegion}
                    />
                </div>
            ))}
        </div>
    );
};

ListCardRegion.propTypes = {
    items: PropTypes.array,
};

ListCardRegion.defaultProps = {
    items: [],
};

export default ListCardRegion;
