import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Map.module.scss';
import CookieOverlay from '../CookieOverlay';
import { useTranslation } from 'next-i18next';
import { CookieStateContext } from '../../containers/BasePage/state';

const Map = ({ lang, coords, address, q, title, zoom }) => {
    const { t } = useTranslation();
    const [showMapWithoutCookieConsent, setShowMapWithoutCookieConsent] =
        useState(false);

    q = coords.lat && coords.lng ? `${coords.lat},${coords.lng}` : q;

    const { state } = useContext(CookieStateContext) || {};
    const cookieConsent = state?.categories?.includes('targeting');

    return (
        <div className={styles['Map']}>
            <div className={styles['Map__Content']}>
                {title && <h4 className={styles['Map__Title']}>{title}</h4>}
                <div className={styles['Map__Wrapper']}>
                    {!(cookieConsent || showMapWithoutCookieConsent) && (
                        <CookieOverlay
                            text={t('map.cookieText')}
                            buttonText={t('map.cookieButton')}
                            modifiers={['Map']}
                            handleClick={() =>
                                setShowMapWithoutCookieConsent(true)
                            }
                        />
                    )}
                    {(cookieConsent || showMapWithoutCookieConsent) && (
                        <iframe
                            id="map"
                            title={address || t('map.defaultIfNoAddress')}
                            className={styles['Map__IframeMap']}
                            src={`https://maps.google.com/maps?q=${q}&hl=${lang}&z=${zoom}&output=embed`}
                        ></iframe>
                    )}
                </div>
            </div>
        </div>
    );
};

Map.propTypes = {
    lang: PropTypes.string,
    q: PropTypes.string,
    coords: PropTypes.object,
    zoom: PropTypes.number,
};

Map.defaultProps = {
    lang: '',
    q: '',
    coords: {},
    zoom: 14,
};

export default Map;
