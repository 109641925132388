import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import styles from './ImageGalleryDownload.module.scss';

const ImageGalleryDownload = ({ download, id }) => {
    const { t } = useTranslation();

    return (
        <a
            id={id}
            className={styles['ImageGalleryDownload']}
            href={download}
            target="_blank"
            rel="noopener ">
            <span className="sr-only">{t('image.download')}</span>
        </a>
    );
};

ImageGalleryDownload.propTypes = {
    download: PropTypes.string.isRequired,
};

ImageGalleryDownload.defaultProps = {
    download: '',
};

export default ImageGalleryDownload;
