import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import throttle from 'lodash.throttle';
import { useTranslation } from 'next-i18next';

import classNames from 'classnames';

import { CookieStateContext } from '../../containers/BasePage/state';
import BackgroundImage from '../BackgroundImage';
import Button from '../Button';
import CookieOverlay from '../CookieOverlay';

import { serializeImage } from '../../utils/SerializeImage';
import { searchToObj } from '../../utils/url';

import styles from './VideoSegment.module.scss';

const isYoutubeVideoUrl = (url) =>
    url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1;

const isVimeoVideoUrl = (url) => url.indexOf('vimeo.com') > -1;

// react-vimeo supports ids OR urls directly, for react-youtube,
// we have to parse the url (youtube.com/youtu.be) ourselves
const parseYoutubeVideoId = (url) => {
    let videoId = null;

    if (url.indexOf('youtu.be') > -1) {
        let l = document.createElement('a');
        l.href = url;

        videoId = l.pathname.slice(1, l.pathname.length);
    } else if (url.indexOf('youtube.com') > -1) {
        const searchString = url.split('?')[1];
        const search = searchToObj(searchString);

        videoId = search && search.v ? search.v : null;
    }

    return videoId;
};

const VideoSegment = ({ overlayImage, videoUrl, modifier }) => {
    const { t } = useTranslation();

    let waitingForPlayer = useRef(null);
    let noOfTries = useRef(0);

    const videoPlayer = useRef(null);
    const [videoType, setVideoType] = useState('');

    const [videoInited, setVideoInited] = useState(false);
    const [videoError, setVideoError] = useState({
        hasError: false,
        message: '',
    });

    const [playVideoWithoutCookieConsent, setPlayVideoWithoutCookieConsent] =
        useState(false);
    const { state } = useContext(CookieStateContext) || {};
    const cookieConsent = state?.categories?.includes('targeting');

    useEffect(() => {
        if (isYoutubeVideoUrl(videoUrl)) {
            setVideoType('youtube');
        } else if (isVimeoVideoUrl(videoUrl)) {
            setVideoType('vimeo');
        } else {
            setError(
                `Unknown provider in video url "${videoUrl}". Only urls YouTube and Vimeo url's beginning with "youtube.com" and "vimeo.com" are supported`
            );
        }
    }, [videoUrl]);

    const setError = (message) =>
        setVideoError({
            hasError: true,
            message,
        });

    const handlePlayVideoClick = throttle(
        () => {
            // hide heading, desc, button + overlay & show player iframe
            setVideoInited(true);

            if (videoError.hasError) {
                return;
            }

            // check if player obj is available
            // necessary as the vimeo player obj is loaded
            // after the play click (whereas the youtube player obj is loaded on page load)
            if (
                !videoPlayer.current ||
                typeof videoPlayer.current.play === 'undefined'
            ) {
                if (!waitingForPlayer.current) {
                    if (++noOfTries.current > 10) {
                        setError('Unknown error - could not load player');
                        return;
                    }

                    console.warn(
                        styles['VideoSegment:'] +
                            ' ' +
                            styles['can'] +
                            ' ' +
                            styles['not'] +
                            ' ' +
                            styles['play'] +
                            ' ' +
                            styles['video'] +
                            ' ' +
                            styles['-'] +
                            ' ' +
                            styles['player'] +
                            ' ' +
                            styles['not'] +
                            ' ' +
                            styles['ready!'] +
                            ' ' +
                            styles['Retrying'] +
                            ' ' +
                            styles['in'] +
                            ' ' +
                            styles['500ms']
                    );

                    handlePlayVideoClick();
                }

                return;
            }

            // actually start playing
            videoPlayer.current.play();
        },
        500,
        { leading: true }
    );

    const handleVimeoPlayerReady = (playerControls) => {
        videoPlayer.current = playerControls;
    };

    const handleYoutubePlayerReady = (event) => {
        // "normalize" the api to match the vimeo player
        videoPlayer.current = {
            play: () => event.target.playVideo(),
        };
    };

    const serializedImage = serializeImage(overlayImage, true);

    const classes = classNames(styles['VideoSegment'], {
        [styles[`VideoSegment--${modifier}`]]: modifier,
    });

    const videoClasses = classNames(styles['VideoSegment__Video'], {
        [styles['VideoSegment__Video--Playing']]: videoInited,
    });

    const sizes = '(max-width: 750px) 100vw, 750px';

    return (
        <div className={classes}>
            {!videoInited &&
                (cookieConsent ||
                    playVideoWithoutCookieConsent ||
                    videoType === 'vimeo') && (
                    <>
                        <div className={styles['VideoSegment__ImageOverlay']}>
                            <BackgroundImage
                                {...serializedImage}
                                quality={70}
                                sizes={sizes}
                                shouldLazyLoad={false}
                            />
                            <div className={styles['VideoSegment__Button']}>
                                <Button
                                    type={'Play'}
                                    onClick={handlePlayVideoClick}
                                />
                            </div>
                        </div>
                    </>
                )}

            <div className={videoClasses}>
                {!videoError.hasError && (
                    <>
                        {(cookieConsent || playVideoWithoutCookieConsent) &&
                            videoType === 'youtube' && (
                                <YouTube
                                    video={parseYoutubeVideoId(videoUrl)}
                                    onReady={handleYoutubePlayerReady}
                                    width="100%"
                                    height="100%"
                                    modestBranding={true}
                                    autoplay={false}
                                    showRelatedVideos={false}
                                    showInfo={false}
                                    volume={0.3}
                                />
                            )}

                        {videoType === 'vimeo' && (
                            <Vimeo
                                video={videoUrl}
                                onReady={handleVimeoPlayerReady}
                                responsive={true}
                                background={false}
                                showTitle={false}
                                showByline={false}
                                volume={0.3}
                                autoplay={false}
                                controls={true}
                                dnt={!cookieConsent}
                            />
                        )}
                    </>
                )}

                {videoError.hasError && (
                    <div className={styles['VideoSegment__Video__Error']}>
                        {videoError.message}
                    </div>
                )}
            </div>

            {!(cookieConsent || playVideoWithoutCookieConsent) &&
                videoType === 'youtube' && (
                    <CookieOverlay
                        text={t('videosegment.cookieText')}
                        buttonText={t('videosegment.cookieButton')}
                        modifiers={['VideoSegment']}
                        handleClick={() => {
                            setPlayVideoWithoutCookieConsent(true);
                            handlePlayVideoClick();
                        }}
                        icon={'/svg/icon-arrow.svg'}
                    />
                )}
        </div>
    );
};

VideoSegment.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    overlayImage: PropTypes.object,
};

VideoSegment.defaultProps = {
    videoUrl: '',
    image: null,
};

export default VideoSegment;
