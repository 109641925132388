import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { isExternalUrl } from '../../utils/url';

import styles from './ListCardToolkit.module.scss';

const ListCardToolkit = ({ title, text, items }) => (
    <div className={styles['ListCardToolkit']}>
        {title && <h2 className={styles['ListCardToolkit__Title']}>{title}</h2>}
        {text && (
            <div className={styles['ListCardToolkit__Preamble']}>{text}</div>
        )}
        {items && (
            <div className={styles['ListCardToolkit__List']}>
                {items.map((item, index) => (
                    <div
                        key={index}
                        className={styles['ListCardToolkit__Toolkit']}
                    >
                        <CardToolkit {...item} />
                    </div>
                ))}
            </div>
        )}
    </div>
);

const CardToolkit = ({ icon, href, title, text }) => {
    const { t } = useTranslation();

    const [isExternal, setIsExternal] = useState(false);
    const classes = classNames(styles['ListCardToolkit__Card__Icon'], {
        [styles[`ListCardToolkit__Card__Icon--${icon}`]]: icon,
    });

    useEffect(() => {
        setIsExternal(isExternalUrl(href));
    }, [href]);

    return (
        <a
            href={href}
            target={isExternal ? '_blank' : null}
            className={styles['ListCardToolkit__Card']}
            aria-label={t('listCardToolkit.altText', { title })}
        >
            {icon && <div className={classes}></div>}
            <h2 className={styles['ListCardToolkit__Card__Title']}>{title}</h2>
            <p className={styles['ListCardToolkit__Card__Text']}>{text}</p>
            <img
                className={styles['ListCardToolkit__Card__Arrow']}
                src={`/svg/icon-arrow-yellow.svg`}
                alt={t('listCardToolkit.altText', { title })}
            />
        </a>
    );
};

ListCardToolkit.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    items: PropTypes.array,
};

ListCardToolkit.defaultProps = {
    title: '',
    text: '',
    items: [],
};

CardToolkit.propTypes = {
    icon: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

CardToolkit.defaultProps = {
    icon: '',
    href: '',
    title: '',
    text: '',
};

export default ListCardToolkit;
