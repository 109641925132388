import React from 'react';
import PropTypes from 'prop-types';
import styles from './TravelTimeHeader.module.scss';

const TravelTimeHeader = ({ title, preamble }) => {
    return (
        <div className={styles['TravelTimeHeader']}>
            <h3 className={styles['TravelTimeHeader__Title']}>{title}</h3>
            <p className={styles['TravelTimeHeader__Preamble']}>{preamble}</p>
        </div>
    );
};

TravelTimeHeader.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
};

TravelTimeHeader.defaultProps = {
    title: '',
    preamble: '',
};

export default TravelTimeHeader;
