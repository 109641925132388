import React, { useEffect, useRef, useContext, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useMediaQuery from '../../utils/useMediaQuery';
import isEmpty from '../../utils/isEmpty';
import VisibilitySensor from 'react-visibility-sensor';
import ImageCaption from './ImageCaption';
import Modal from '../Modal';
import ImageGalleryPager from './ImageGalleryPager';
import ImageGalleryThumbnail from './ImageGalleryThumbnail';
import ImageGalleryResponsiveFullscreenImage from './ImageGalleryResponsiveFullscreenImage';
import BackgroundImage from '../BackgroundImage';
import ImageGalleryDownload from './ImageGalleryDownload';
import ImageGalleryFullscreenCaption from './ImageGalleryFullscreenCaption';
import ImageGalleryFullscreenButton from './ImageGalleryFullscreenButton';
import { serializeImage } from '../../utils/SerializeImage';
import { breakpoints } from '../../constants';
import { useLocalStateContext, LocalStateContext } from './state';
import { useTranslation } from 'next-i18next';
import styles from './ImageGallery.module.scss';

const ImageGallery = ({ items = [], title }) => {
    const serializedImages = items.map((item, i) => ({
        ...serializeImage(item, 'true'),
        id: item.id,
        index: i + 1,
        download: item.download,
    }));

    const context = useLocalStateContext(serializedImages);
    const { state, handleFullscreenToggle } = context;

    const isMobile = useMediaQuery({
        query: `(max-width: ${breakpoints.sm}px)`,
    });

    if (isEmpty(items)) {
        return null;
    }

    if (serializedImages.length === 1) {
        return (
            <SingleImageInlineGallery
                title={title}
                image={serializedImages[0]}
            />
        );
    }

    return (
        <LocalStateContext.Provider value={context}>
            <InlineGallery title={title} images={serializedImages} />
            <Modal
                open={state.isFullscreen && !isMobile}
                handleClose={handleFullscreenToggle}
            >
                <FullscreenImageGallery images={serializedImages} />
            </Modal>
        </LocalStateContext.Provider>
    );
};

ImageGallery.propTypes = {
    title: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                    .isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string,
                title: PropTypes.string,
                alt: PropTypes.string,
            }),
        })
    ),
};

ImageGallery.defaultProps = {
    title: null,
};

const SingleImageInlineGallery = ({ title, image }) => {
    return (
        <div className={styles['ImageGallery']}>
            <div className={styles['ImageGallery__Container']}>
                {title && (
                    <h5 className={styles['ImageGallery__title']}>{title}</h5>
                )}
                <div
                    className={
                        styles['ImageGallery__Wrapper'] +
                        ' ' +
                        styles['ImageGallery__Wrapper--SingleImage']
                    }
                >
                    <GalleryImage image={image} />
                    {image.download && (
                        <ImageGalleryDownload
                            download={image.download}
                            id={`image-${image.id}`}
                        />
                    )}
                    <ImageCaption
                        caption={
                            <ImageDescription
                                id={`image-${image.id}`}
                                title={image.title}
                                caption={image.caption}
                                credits={image.credits}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

SingleImageInlineGallery.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
};

const ImageDescription = ({ credits, caption }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['ImageGallery__ImageDescription']}>
            {caption && (
                <p className={styles['ImageGallery__ImageDescription__Text']}>
                    {caption}
                </p>
            )}
            {credits && (
                <p
                    className={
                        styles['ImageGallery__ImageDescription__Credits']
                    }
                >
                    {t('imagegallery.photo')}: {credits}
                </p>
            )}
        </div>
    );
};

ImageDescription.propTypes = {
    title: PropTypes.string,
    credits: PropTypes.string,
    caption: PropTypes.string,
};

ImageDescription.defaultProps = {
    title: '',
    caption: '',
    credits: '',
};

const InlineGallery = ({ title, images }) => {
    const { state, ...handlers } = useContext(LocalStateContext);

    const isDesktop = useMediaQuery({
        query: `(min-width: ${breakpoints.l}px)`,
    });

    const imagesToRender = !isDesktop ? images : images.slice(0, 5);
    const imagesLeft = images.length - imagesToRender.length;

    return (
        <div className={styles['ImageGallery']}>
            <div className={styles['ImageGallery__Container']}>
                {title && (
                    <h5 className={styles['ImageGallery__title']}>{title}</h5>
                )}

                <div className={styles['ImageGallery__Wrapper']}>
                    <ImageGalleryPager
                        untranslatedModifiers={[
                            'ImageGalleryPager--Desktop-only',
                        ]}
                        current={state.selectedImageIndex}
                        total={images.length}
                    />
                    <ImageGalleryFullscreenButton
                        onClick={handlers.handleFullscreenToggle}
                    />

                    <GalleryImage
                        image={state.selectedImage}
                        onClick={handlers.handleFullscreenToggle}
                    />

                    <ImageGalleryArrows images={images} />
                    {state.selectedImage.download && (
                        <ImageGalleryDownload
                            download={state.selectedImage.download}
                            id={`image-${state.selectedImage.id}`}
                        />
                    )}
                    <ImageCaption
                        caption={
                            <ImageDescription
                                title={state.selectedImage.title}
                                caption={state.selectedImage.caption}
                                credits={state.selectedImage.credits}
                            />
                        }
                    />
                </div>

                <div className={styles['ImageGallery__Thumbnails']}>
                    <ImageGalleryPager
                        current={state.displayMultiThumbIndex}
                        total={images.length}
                        untranslatedModifiers={[
                            'ImageGalleryPager--Responsive-only',
                        ]}
                    />
                    {imagesToRender.map((image) => (
                        <VisibilitySensor
                            key={image.id}
                            delayedCall={true}
                            scrollCheck={true}
                            scrollDelay={30}
                            onChange={(visible) =>
                                handlers.handleImageInViewChange(visible, image)
                            }
                        >
                            <ImageGalleryThumbnail
                                image={image}
                                onImageSelect={handlers.handleImageSelect}
                                onFullscreenToggle={
                                    handlers.handleFullscreenToggle
                                }
                            />
                        </VisibilitySensor>
                    ))}
                    {isDesktop && imagesLeft > 0 && (
                        <div
                            className={
                                styles[
                                    'ImageGallery__Thumbnails__MoreAvailable'
                                ]
                            }
                        >
                            +{imagesLeft}
                            <span className="sr-only">
                                {imagesLeft} available
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

InlineGallery.propTypes = {
    title: PropTypes.string,
    images: PropTypes.array.isRequired,
};

InlineGallery.defaultProps = {
    title: null,
    images: [],
};

const FullscreenImageGallery = ({ images }) => {
    const thumbRefs = useRef([]);
    const { state, ...handlers } = useContext(LocalStateContext);

    const isDesktop = useMediaQuery({
        query: `(min-width: ${breakpoints.l}px)`,
    });

    const thumbContainerClasses = classNames(
        styles['ImageGallery__Thumbnails'],
        {
            [styles['ImageGallery__Thumbnails--Fullscreen']]: !isDesktop,
        }
    );

    useEffect(() => {
        const timeOut = isDesktop ? 0 : 500;

        setTimeout(() => {
            const selectedThumbRef =
                thumbRefs.current[state.selectedImage.index - 1];

            if (selectedThumbRef) {
                selectedThumbRef.scrollThumbIntoView();
            }
        }, timeOut);
    }, [isDesktop, state.selectedImageIndex, state.selectedImage.index]);

    return (
        <div
            className={
                styles['ImageGallery'] +
                ' ' +
                styles['ImageGallery--Fullscreen']
            }
        >
            <div
                className={
                    styles['ImageGallery__Container'] +
                    ' ' +
                    styles['ImageGallery__Container--Fullscreen']
                }
            >
                <div
                    className={
                        styles['ImageGallery__Wrapper'] +
                        ' ' +
                        styles['ImageGallery__Wrapper--Fullscreen']
                    }
                >
                    <ImageGalleryPager
                        modifiers="ImageGalleryPager--Desktop-only"
                        current={state.selectedImageIndex}
                        total={images.length}
                    />

                    <GalleryImage image={state.selectedImage} />
                    {state.selectedImage.download && (
                        <ImageGalleryDownload
                            download={state.selectedImage.download}
                            id={`image-${state.selectedImage.id}`}
                        />
                    )}
                    <ImageCaption
                        caption={
                            <ImageDescription
                                title={state.selectedImage.title}
                                caption={state.selectedImage.caption}
                                credits={state.selectedImage.credits}
                            />
                        }
                    />
                    {isDesktop && <ImageGalleryArrows images={images} />}
                </div>

                {!isDesktop && (
                    <div className={styles['ImageGallery__ThumbnailsWrapper']}>
                        <div className={thumbContainerClasses}>
                            {images.map((image, i) => (
                                <VisibilitySensor
                                    active={!isDesktop}
                                    key={image.id}
                                    scrollCheck={true}
                                    scrollDelay={30}
                                    onChange={(visible) =>
                                        handlers.handleImageInViewChange(
                                            visible,
                                            image
                                        )
                                    }
                                >
                                    <ImageGalleryResponsiveFullscreenImage
                                        image={image}
                                        ref={(el) =>
                                            (thumbRefs.current[i] = el)
                                        }
                                    >
                                        <ImageGalleryFullscreenCaption
                                            image={image}
                                            isActive={
                                                state.displayThumbIndex ===
                                                image.index
                                            }
                                            totalImageCount={images.length}
                                        />
                                    </ImageGalleryResponsiveFullscreenImage>
                                </VisibilitySensor>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {isDesktop && (
                <div
                    className={
                        styles['ImageGallery__ThumbnailsWrapper'] +
                        ' ' +
                        styles['ImageGallery__ThumbnailsWrapper--Fullscreen']
                    }
                >
                    <div className={thumbContainerClasses}>
                        {images.map((image, i) => (
                            <VisibilitySensor
                                active={!isDesktop}
                                key={image.id}
                                scrollCheck={true}
                                scrollDelay={30}
                                onChange={(visible) =>
                                    handlers.handleImageInViewChange(
                                        visible,
                                        image
                                    )
                                }
                            >
                                <ImageGalleryThumbnail
                                    ref={(el) => (thumbRefs.current[i] = el)}
                                    image={image}
                                    onImageSelect={handlers.handleImageSelect}
                                    active={
                                        state.selectedImageIndex === image.index
                                    }
                                />
                            </VisibilitySensor>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

FullscreenImageGallery.propTypes = {
    images: PropTypes.array.isRequired,
};

const GalleryImage = ({ image, onClick }) => {
    const imageClasses = classNames(styles['ImageGallery__Image'], {
        [styles['ImageGallery__Image--Portrait']]: image.portrait,
        [styles['ImageGallery__Image--Clickable']]: onClick !== null,
    });

    const sizes = '(max-width: 1200px) 50vw, 1200px';

    const WrapperElement = onClick ? 'button' : 'div';

    return (
        <WrapperElement onClick={onClick} className={imageClasses}>
            <BackgroundImage {...image} sizes={sizes} />
        </WrapperElement>
    );
};

GalleryImage.defaultProps = {
    image: {},
    onClick: null,
};

GalleryImage.propTypes = {
    image: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
        index: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string,
        alt: PropTypes.string,
        title: PropTypes.string,
        focal: PropTypes.object,
        portrait: PropTypes.bool,
    }),
    onClick: PropTypes.func,
};

const ImageGalleryArrows = ({ images }) => {
    const { state, ...handlers } = useContext(LocalStateContext);

    const selectPrevImage = useCallback(
        (e) => {
            const prevImgIndex = state.selectedImageIndex - 2;

            if (!(prevImgIndex in images)) {
                handlers.handleImageSelect(e, images[images.length - 1]);
                return;
            }

            handlers.handleImageSelect(e, images[prevImgIndex]);
        },
        [state, images, handlers]
    );

    const selectNextImage = useCallback(
        (e) => {
            const nextImgIndex = state.selectedImageIndex;

            if (!(nextImgIndex in images)) {
                handlers.handleImageSelect(e, images[0]);
                return;
            }

            handlers.handleImageSelect(e, images[nextImgIndex]);
        },
        [state, images, handlers]
    );

    const handleArrowKeyPress = useCallback(
        (e) => {
            const leftArrowCode = 37;
            const rightArrowCode = 39;

            if (e.keyCode === leftArrowCode) {
                selectPrevImage(e);
            }

            if (e.keyCode === rightArrowCode) {
                selectNextImage(e);
            }
        },
        [selectPrevImage, selectNextImage]
    );

    useEffect(() => {
        if (state.isFullscreen) {
            document.addEventListener('keydown', handleArrowKeyPress, false);
        }

        return () => {
            document.removeEventListener('keydown', handleArrowKeyPress, false);
        };
    }, [handleArrowKeyPress, state.isFullscreen]);

    return (
        <>
            <span>
                <button
                    className={styles['ImageGallery__ArrowLeft']}
                    onClick={selectPrevImage}
                >
                    <span className="sr-only">Previous image</span>
                </button>
            </span>

            <span>
                <button
                    className={styles['ImageGallery__ArrowRight']}
                    onClick={selectNextImage}
                >
                    <span className="sr-only">Next image</span>
                </button>
            </span>
        </>
    );
};

ImageGalleryArrows.defaultProps = {
    images: {},
};

ImageGalleryArrows.propTypes = {
    images: PropTypes.array,
};

export { ImageDescription };
export default ImageGallery;
