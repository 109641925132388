import React, { useEffect, useCallback, useRef } from 'react';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

let noActiveModals = 0;

const useBodyPortal = () => {
    let modalRootRef = useRef(null);

    useEffect(() => {
        // handle dev server iframe document when creating iframe
        const documentElement =
            window.innerWidth === 0 ? window.parent.document : document;
        modalRootRef.current = documentElement.createElement('div');

        const ref = modalRootRef.current;
        const modalRoot = documentElement.body;

        modalRoot.appendChild(ref);

        return () => {
            ref.remove();
        };
    }, []);

    return modalRootRef.current;
};

const Modal = ({ children, open, handleClose, showCloseButton }) => {
    const wrapperRef = useRef(null);
    const modalContainer = useBodyPortal();

    const handleKeypress = useCallback(
        (e) => {
            e.stopPropagation();

            if (e.keyCode === 27 && typeof handleClose === 'function') {
                if (
                    noActiveModals < 2 ||
                    wrapperRef.current.contains(document.activeElement)
                ) {
                    // handle nested/multiple modals correctly
                    handleClose(e);
                }
            }
        },
        [handleClose]
    );

    const handleOutsideClick = useCallback(
        (e) => {
            e.persist();
            e.stopPropagation();

            if (
                typeof handleClose === 'function' &&
                wrapperRef.current &&
                wrapperRef.current === e.target
            ) {
                handleClose(e);
            }
        },
        [handleClose]
    );

    // restore scroll pos
    useEffect(() => {
        if (!open) {
            return;
        }

        noActiveModals++;

        const origScrollPos =
            window.pageYOffset || document.documentElement.scrollTop;

        document.addEventListener('keydown', handleKeypress, false);
        document.body.style.overflow = 'hidden';

        return () => {
            // handle nested/multiple modals correctly
            noActiveModals--;
            if (noActiveModals === 0) {
                document.removeEventListener('keydown', handleKeypress, false);
                document.body.style.overflow = 'auto';

                window.scrollTo(0, origScrollPos);
            }
        };
    }, [open, handleKeypress]);

    // set focus on mount
    useEffect(() => {
        if (!open || !wrapperRef.current) {
            return;
        }

        wrapperRef.current.focus();
    }, [open]);

    if (!open || !modalContainer) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={styles['Modal']}>
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <div
                role="dialog"
                tabIndex="-1"
                ref={wrapperRef}
                className={styles['Modal__Inner']}
                onKeyPress={handleKeypress}
                onClick={handleOutsideClick}
            >
                {showCloseButton && (
                    <button
                        className={styles['Modal__CloseButton']}
                        onClick={handleClose}
                    >
                        <span className="sr-only">Close</span>
                    </button>
                )}
                {children}
            </div>
        </div>,
        modalContainer
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    handleClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
    handleClose: () => {},
    showCloseButton: true,
};

export default Modal;
