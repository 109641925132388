import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CookieOverlay.module.scss';
import Button from '../Button';

const CookieOverlay = ({
    text,
    buttonText,
    modifiers = [],
    handleClick,
    icon,
}) => {
    const classes = classNames(
        styles['CookieOverlay'],
        modifiers.map((modifier) => styles[`CookieOverlay--${modifier}`])
    );

    return (
        <div className={classes}>
            <p
                className={styles['CookieOverlay__Text']}
                dangerouslySetInnerHTML={{ __html: text }}
            />
            <Button
                type="Primary"
                onClick={handleClick}
                text={buttonText}
                icon={icon}
            />
        </div>
    );
};

CookieOverlay.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    handleClick: PropTypes.func,
    icon: PropTypes.string,
};

CookieOverlay.defaultProps = {};

export default CookieOverlay;
