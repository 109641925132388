import React from 'react';
import PropTypes from 'prop-types';

import CardContact from '../CardContact';

import styles from './ListCardContact.module.scss';

const ListCardContact = ({ title, text, items }) => {
    return (
        <div className={styles['ListCardContact']}>
            {items && items.length > 0 && (
                <>
                    {title && (
                        <h2 className={styles['ListCardContact__Title']}>
                            {title}
                        </h2>
                    )}
                    {text && (
                        <div className={styles['ListCardContact__Preamble']}>
                            {text}
                        </div>
                    )}
                    {items.map((item, index) => (
                        <CardContact key={index} {...item} />
                    ))}
                </>
            )}
        </div>
    );
};

ListCardContact.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    items: PropTypes.array,
};

ListCardContact.defaultProps = {
    title: '',
    text: '',
    items: [],
};

export default ListCardContact;
